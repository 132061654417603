import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "notification hasFooter" }
const _hoisted_2 = {
  key: 0,
  class: "pt-4"
}
const _hoisted_3 = { class: "notificationImage" }
const _hoisted_4 = { class: "notificationDetails" }
const _hoisted_5 = { class: "fs_14 greyBlue ln_12 d-block mb-1" }
const _hoisted_6 = { class: "fs_10 greyBlue d-block mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _directive_userimage = _resolveDirective("userimage")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_mini_spinner)
        ]))
      : (_ctx.notifications.length == 0)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "app_nessunanotifica",
            text: "Nessuna notifica",
            class: "fs_14 grey d-block text-center mt-4"
          }))
        : (_ctx.notifications.length > 0)
          ? (_openBlock(true), _createBlock(_Fragment, { key: 2 }, _renderList(_ctx.notifications, (item, i) => {
              return (_openBlock(), _createBlock("div", {
                class: ["notificationRow", { toRead: !item.readOn }],
                key: i,
                onClick: ($event: any) => (_ctx.openNotification(item))
              }, [
                _withDirectives(_createVNode("div", _hoisted_3, null, 512), [
                  [_directive_userimage, _ctx.$store.state.getUserImage(item.data.userIdentifier)]
                ]),
                _createVNode("div", _hoisted_4, [
                  _createVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
                  _createVNode("div", {
                    class: "fs_12 greyBlue ln_12 d-block",
                    innerHTML: item.text
                  }, null, 8, ["innerHTML"]),
                  _createVNode("div", _hoisted_6, _toDisplayString(_ctx.$filters.date(item.createdOn, 'DD MMM YYYY')) + " - " + _toDisplayString(_ctx.$filters.time(item.createdOn)), 1)
                ])
              ], 10, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true)
  ]))
}