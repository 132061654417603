
import { Options, Vue } from 'vue-class-component';
import { NotificationClient } from '@/services/Services';
import CommentsModal from '@/components/commentsModal.vue';
import store from '@/store';
import * as OM from '@/model';
import * as CONST from '@/const';

@Options({})
export default class Notification extends Vue {

    loading: boolean = false;
    notifications: OM.Notification[] = [];

    created() {
        this.loading = true;

        NotificationClient.setNotificationOpened(false);
        NotificationClient.getNotifications(false)
        .then(x => {
            this.notifications = x;
            store.state.newNotificationCount = 0;
            // let filtered = this.notifications.filter(x => !x.readOn);
            // let ids = filtered.map(x => x.identifier);
            // NotificationClient.setReadOn(ids);
        })
        .finally(() => this.loading = false);
    }

    openNotification(payload: OM.Notification){
        NotificationClient.setReadOn(payload.identifier, false);
        let route = '';
        if(payload.data.route){
            route = payload.data.route;
            if(payload.data.notificationType == CONST.NotificationType.AllegatoNew ||
                payload.data.notificationType == CONST.NotificationType.FieraPostNew ||
                payload.data.notificationType == CONST.NotificationType.CommunityPostNew ||
                payload.data.notificationType == CONST.NotificationType.EventPostNew){
                //aprire modal del post? No non direi va bene anche senza
            } else if(payload.data.notificationType == CONST.NotificationType.AllegatoComment ||
                payload.data.notificationType == CONST.NotificationType.CommunityPostComment ||
                payload.data.notificationType == CONST.NotificationType.EventPostComment ||
                payload.data.notificationType == CONST.NotificationType.FieraPostComment){
                this.$opModal.show(CommentsModal, {
                    parentIdentifier: payload.data.parentIdentifier,
                    //grandParentIdentifier: payload.data.grandParentIdentifier,
                    containerIdentifier: payload.data.containerIdentifier,
                    commentType: payload.data.notificationType
                })
            } else if (payload.data.notificationType == CONST.NotificationType.AllegatoCommentComment ||
                payload.data.notificationType == CONST.NotificationType.CommunityPostCommentComment ||
                payload.data.notificationType == CONST.NotificationType.EventPostCommentComment ||
                payload.data.notificationType == CONST.NotificationType.FieraPostCommentComment){
                var parentCommentType;
                if(payload.data.notificationType == CONST.NotificationType.AllegatoCommentComment)
                    parentCommentType = CONST.NotificationType.AllegatoComment;
                else if(payload.data.notificationType == CONST.NotificationType.CommunityPostCommentComment)
                    parentCommentType = CONST.NotificationType.CommunityPostComment;
                else if(payload.data.notificationType == CONST.NotificationType.EventPostCommentComment)
                    parentCommentType = CONST.NotificationType.EventPostComment;
                else if(payload.data.notificationType == CONST.NotificationType.FieraPostCommentComment)
                    parentCommentType = CONST.NotificationType.FieraPostComment;
                this.$opModal.show(CommentsModal, {
                    parentIdentifier: payload.data.grandParentIdentifier,
                    //grandParentIdentifier: payload.data.grandParentIdentifier,
                    containerIdentifier: payload.data.containerIdentifier,
                    commentType: parentCommentType
                })
                this.$opModal.show(CommentsModal, {
                    parentIdentifier: payload.data.parentIdentifier,
                    grandParentIdentifier: payload.data.grandParentIdentifier,
                    containerIdentifier: payload.data.containerIdentifier,
                    commentType: payload.data.notificationType
                })
            }
            this.$router.push(route);
        }
    }

}
